import React from 'react';
import { useTranslation } from 'react-i18next';

import { URLEnums } from 'enum/URLEnum';

const CookieBanner = () => {
  const { t } = useTranslation();

  return (
    <div
      key="cookie-agreement-banner"
      id="cookie-agreement-banner"
      className="privacy-cookies-container cookie-banner-hidden"
    >
      <div className="privacy-cookies-content paragraph">
        {t('This website uses cookies to enhance your experience and for web analytics purposes. Read our')}{' '}
        <a href={t<string>(URLEnums.PrivacyPolicy)}>{t('Privacy notice')}</a>
        {t(' to learn more or')}{' '}
        <a href={t<string>(URLEnums.PrivacyPolicyCookie)}>{t('to change your cookie preferences')}</a>
        {t('. By continuing to browse the site, you agree to our use of cookies.')}
      </div>
      <div className="privacy-cookies-button-wrapper">
        <button
          id="confirm-cookie-button"
          type="button"
          className="privacy-cookies-button"
          aria-label={t<string>('I agree')}
        >
          {t('I agree')}
        </button>
      </div>
    </div>
  );
};

export default CookieBanner;
