export const enum URLEnums {
  Home = '/',
  Company = '/company',
  About = '/company/about',
  Experts = '/experts',
  Blog = '/blog',
  Careers = '/careers',
  MobileBanking = '/banking/mobile',
  Contacts = '/contacts',
  ContactsOffices = '/contacts#offices',
  PrivacyPolicy = '/privacy-notice',
  PrivacyPolicyCookie = '/privacy-notice#cookie-dif',
  Services = '/services',
  DigitalEnterprise = '/solutions/digital-enterprise',
  Thanks = '/thanks',
  MachineLearning = '/machine-learning/consulting',
  Impressum = '/impressum',
}
