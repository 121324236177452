/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
// You can delete this file if you're not using it
import { wrapPageElementWithBanner } from './scripts/wrapPageElement/wrapPageElement';

import './src/styles/index.scss';

// This is needed to not trigger CookieBanner rerendering on page change (the same is in gatsby-ssr.js)
export const wrapPageElement = wrapPageElementWithBanner;
