import React from 'react';

import CookieBanner from '../../src/components/CookieBanner/CookieBanner';

export const wrapPageElementWithBanner = ({ element, props }) => {
  // gatsby i18next already uses wrapPageElement to wrap all pages with context provider, so if
  // we'd like to get access to it in cookie banner, we need to copy it
  // I strongly believe that gatsby i18next plugin should use wrapRootElement instead of wrapPageElement

  const newElement = React.cloneElement(
    element, // Symbol(react.fragment)
    element.props,
    element.props.children[0], // React Header
    React.cloneElement(
      element.props.children[1], //  I18nextProvider
      element.props.children[1].props,
      React.cloneElement(
        element.props.children[1].props.children, // I18nextContext.Provider
        element.props.children[1].props.children.props,
        React.createElement(
          React.Fragment,
          undefined,
          element.props.children[1].props.children.props.children,
          <CookieBanner />,
        ),
      ),
    ),
  );

  return newElement;
};
